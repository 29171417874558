.loginContainer {
    display: flex;
    justify-content: flex-end;
    width: 100vw;
    height: 100vh;
    /* background: url('../../../../public/images/login-background/sso.svg'); */
    background-size: cover;
}

.loginCard{
    border: 0.71px solid rgba(229, 238, 255, 1);
    background: rgba(255, 255, 255, 0.20);
    box-shadow: 2.8458333015441895px 2.8458333015441895px 11.383333206176758px 0px rgba(146, 148, 151, 0.25);
    backdrop-filter: blur(21.34375px);
}

.imageLogo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}

.imageKnow {
    margin-bottom: 4%
}

.fontLabel {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 400;
    justify-content: center;
    display: flex;
    color: rgba(255, 255, 255, 1);
}

.signInClass {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
